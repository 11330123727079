<template lang="pug">
  .user-manage
    #blog-topImg
    .blog-title-text.mt-5.text-center {{title}}
    .container.pb-5
      .text-center.blog-content-text
        .mt-3 {{date}}
        .mt-3.text-left
          span(v-html="text")

        hr.mt-5(color='#CCCCCC' width='100%' size='3')
        a.d-inline-block(href='/blog')
          .mt-5.blog-button.px-4.py-1 ブログ一覧


</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      dateInfo: this.$route.params.date,
      title:'',
      text:'',
      date:'',
    }
  },
  created() {
    this.dateFix()
    this.getBlogText()
  },
  methods: {
    dateFix() {
      
    },
    //ブログ記事データ取得
    getBlogText() {
      let a = this.dateInfo.slice(0,4)
      let b = this.dateInfo.slice(4,6)
      let c = this.dateInfo.slice(6,8)
      let d = '-'

      this.dateInfo = a + d + b + d + c

      axios.get(process.env.VUE_APP_API_GATEWAY + '/api-blog', {
        params: {
          "type": "article",
          "date": this.dateInfo,
        },
        headers: {
          'Authorization': this.$user.idToken
        }
      })
      .then(res => {
        this.title = res.data.title
        this.date = res.data.date.replace(/-/g, '/')
        this.text = res.data.article
      })
      .catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="stylus">
body
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif
  letter-spacing: .1rem
  line-height: 1.7
  background-color: #ffffff

a
  color: #2980e8

  &:hover
    opacity: .8
    text-decoration: none

.blog-text-color
  color: #2B546A

  &:hover
    text-decoration: none
    color: #2B546A

.title-text
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

#blog-topImg
  background-image: url(/img/blog/blog_img.jpg)
  height: 310px
  width: 100%
  background-repeat: no-repeat
  background-size: cover
  background-position: center
  letter-spacing: .4rem
  line-height: 2
  z-index: 1
  position: relative
  opacity: .6

.blog-title-text
  font-size: 1.6rem
  color: #0664a9
  font-weight: normal
  margin: 1.5rem 0
  text-align: center

.blog-content-text
  color: #2B546A

.blog-button
  border: solid 1px #2B546A
  color: #2B546A
  border-radius: 15px
  transition: .4s

  &:hover
    background: #2B546A
    border: solid 1px #2B546A
    color: #ffffff
    opacity: 1

.twitter-icon, .facebook-icon, .line-icon
  width: 2.9rem

.blog-content-text
  padding: 0rem 17rem
  letter-spacing: 3px
  line-height: 2x

.blogImg
  width: 300px
  height: 225px

@media (max-width: 1199.98px)
  .blog-content-text
    padding: 0rem 12rem
    letter-spacing: 3px
    line-height: 2x

@media (max-width: 991.98px)
  .blog-content-text
    padding: 0rem 6rem
    letter-spacing: 3px
    line-height: 2x

// スマホ表示
@media (max-width: 767px)
  .blog-content-text
    padding: 0rem 1rem
    font-size: medium
    letter-spacing: 3px
    line-height: 2x

  .blog-title-text
    font-size: 1.4rem

</style>
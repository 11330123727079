var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-manage" }, [
    _c("div", { attrs: { id: "blog-topImg" } }),
    _c("div", { staticClass: "blog-title-text mt-5 text-center" }, [
      _vm._v(_vm._s(_vm.title))
    ]),
    _c("div", { staticClass: "container pb-5" }, [
      _c("div", { staticClass: "text-center blog-content-text" }, [
        _c("div", { staticClass: "mt-3" }, [_vm._v(_vm._s(_vm.date))]),
        _c("div", { staticClass: "mt-3 text-left" }, [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.text) } })
        ]),
        _c("hr", {
          staticClass: "mt-5",
          attrs: { color: "#CCCCCC", width: "100%", size: "3" }
        }),
        _vm._m(0)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "d-inline-block", attrs: { href: "/blog" } },
      [
        _c("div", { staticClass: "mt-5 blog-button px-4 py-1" }, [
          _vm._v("ブログ一覧")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }